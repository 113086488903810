/**
 * Definition file for dynamic routes with custom
 * parameter values (for /[locale]/[page] routes only).
 *
 * Note:
 * The component for the route must be imported
 * in "./components/Pages/index.js"
 * and contentType must match the exported component
 * key
 */

const CUSTOM_ROUTES = {
  '/en/thank-you': {
    contentType: 'thankYou',
  },
  '/de/danke': {
    contentType: 'thankYou',
  },
  '/en/telemedicine': {
    contentType: 'videoConsultationLandingPage',
  },
  '/de/telemedizin': {
    contentType: 'videoConsultationLandingPage',
  },
  '/en/reviews': {
    contentType: 'reviewsPage',
  },
  '/de/bewertungen': {
    contentType: 'reviewsPage',
  },
  '/en/paymentupdate': {
    contentType: 'paymentUpdatePage',
  },
  '/de/paymentupdate': {
    contentType: 'paymentUpdatePage',
  },
  '/en/invite-friends/reward': {
    contentType: 'referFriendRewardLandingPage',
    page: 'reward',
    firstLevelPageSlug: {
      en: 'invite-friends',
    },
    slugParts: ['en', 'invite-friends', 'reward'],
  },
  '/de/invite-friends/reward': {
    contentType: 'referFriendRewardLandingPage',
    page: 'invite-friends',
    firstLevelPageSlug: {
      de: 'invite-friends',
    },
    slugParts: ['de', 'invite-friends', 'reward'],
  },
};

export const getCustomRoutes = async (params, routesConfig = CUSTOM_ROUTES) => {
  const { locale, page } = params;

  return Object.keys(routesConfig)
    .map((key) => {
      const [locale, page, subpage] = key.substring(1).split('/');
      if (subpage) {
        return {
          ...routesConfig[key],
          locale,
          page,
          subpage,
          isCustomRoute: true,
        };
      }
      return { ...routesConfig[key], locale, page, isCustomRoute: true };
    })
    .filter(
      (route) =>
        (locale === '*' || locale === route.locale) &&
        (page === '*' || page === route.page),
    );
};

export const getCustomContentTypes = () => {
  const customContentTypes = Object.keys(CUSTOM_ROUTES).map(
    (key) => CUSTOM_ROUTES[key].contentType,
  );

  return Array.from(new Set(customContentTypes));
};

export default CUSTOM_ROUTES;
