import { PRODUCTION } from 'constants/environment';
import { useContext, useEffect, useState } from 'react';
import config from 'config';
import { AppInitContext } from 'context/appInit';
import { useTranslation } from 'hooks/useTranslation';

const CookieNotification = (): JSX.Element => {
  const { locale } = useTranslation();
  const { hasAppInit } = useContext(AppInitContext);
  const [hasScriptLoaded, setHasScriptLoaded] = useState(false);

  useEffect(() => {
    if (hasAppInit) {
      const { cookieBanner } = config.defaults;

      let mainScriptSrc = '';
      if (PRODUCTION) {
        mainScriptSrc = `${cookieBanner.domains.production}/${
          cookieBanner[locale as keyof typeof cookieBanner]
        }.js`;
      } else {
        mainScriptSrc = `${cookieBanner.domains.staging}/${
          cookieBanner[locale as keyof typeof cookieBanner]
        }-test.js`;
      }

      // Create script
      const mainScript = document.createElement('script');
      mainScript.src = mainScriptSrc;
      mainScript.async = true;

      const script = document.createElement('script');
      script.innerHTML = `function OptanonWrapper() {}`;
      document.head.appendChild(mainScript);
      document.head.appendChild(script);

      setHasScriptLoaded(true);

      return () => {
        document.head.removeChild(mainScript);
        document.head.removeChild(script);
      };
    }
  }, [hasAppInit]);

  useEffect(() => {
    if (hasScriptLoaded) {
      const t = setTimeout(() => {
        if (
          // eslint-disable-next-line
          // @ts-ignore
          window.Optanon &&
          !document.querySelector('.optanon-alert-box-wrapper')
        ) {
          try {
            // eslint-disable-next-line
            // @ts-ignore
            window.Optanon.LoadBanner();
          } catch (err) {
            console.log(err);
          }
        }
      }, 2000);

      return () => {
        clearTimeout(t);
      };
    }
  }, [hasScriptLoaded]);

  return <></>;
};

export default CookieNotification;
