import { QUNOMEDICAL_BRAND_ID } from 'constants/brand';
import { PRODUCTION, STAGE } from 'constants/environment';
import * as Sentry from '@sentry/react';
import CookieNotification from 'components/Common/CookieNotification';
import FullStory from 'components/Common/FullStory';
import config from 'config';
import { AppInitContext } from 'context/appInit';
import { CurrencyContext } from 'context/currency';
import { FunnelContext } from 'context/funnel';
import { LocaleContext } from 'context/locale';
import { useAnalytics } from 'hooks/useAnalytics';
import 'intersection-observer';
import Cookie from 'js-cookie';
import localesContent from 'locales';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import 'scripts/polyfills';
import {
  storeUserCountry,
  storeUserJourney,
} from 'services/tracking/userJourney';
import '../styles/main.scss';

Sentry.init({
  enabled: PRODUCTION,
  dsn: process.env.SENTRY_DSN,
  environment: process.env.STAGE || STAGE.LOCAL,
  autoSessionTracking: false,
});

type PageProps = object;

type Props = {
  Component: (props: PageProps) => JSX.Element;
  pageProps: PageProps;
};

const App = ({ Component, pageProps }: Props): JSX.Element => {
  const router = useRouter();
  // eslint-disable-next-line
  // @ts-ignore
  const { brand } = pageProps;

  /**
   * HACK:
   *
   * Since at Vercel, on fallback routes, the dynamic routes
   * default to the dynamic variable name (in this case, `[locale]`)
   * when they are not computed, we need to fallback here both for
   * when it is not defined and when it is '[locale]'.
   */
  let routerLocale = router.query.locale;
  if (routerLocale === '[locale]' || !routerLocale) {
    routerLocale = config.defaultLocale;
  }

  const [locale, setLocale] = useState<string>(routerLocale as string);
  const [currency, setCurrency] = useState<string>('');

  const [hasAppInit, setHasAppInit] = useState(false);

  const [externalFunnelLoaded, setExternalFunnelLoaded] = useState(false);

  const { init, trackPageViewed } = useAnalytics();

  useEffect(() => {
    router.events.on('routeChangeComplete', () => {
      trackPageViewed();
    });

    setCurrency(Cookie.get('qm_currency') || config.defaults.currency);
    storeUserJourney(router.asPath, document.referrer);
    storeUserCountry();
  }, []);

  useEffect(() => {
    if (!brand || brand?.sys.id !== QUNOMEDICAL_BRAND_ID || hasAppInit) {
      return;
    }

    window.addEventListener('mousemove', handleAppInit);
    window.addEventListener('touchstart', handleAppInit);
    window.addEventListener('scroll', handleAppInit, { passive: true });

    const timer = setTimeout(() => {
      if (!hasAppInit) {
        handleAppInit();

        // clear the old timer
        if (timer) {
          clearTimeout(timer);
        }
      }
    }, 8000);

    return () => {
      window.removeEventListener('mousemove', handleAppInit);
      window.removeEventListener('touchstart', handleAppInit);
      window.removeEventListener('scroll', handleAppInit);
      clearTimeout(timer);
    };
  }, [brand]);

  const handleAppInit = (): void => {
    setHasAppInit(true);
    init(config.defaults.googleAnalytics);
    trackPageViewed();

    window.removeEventListener('mousemove', handleAppInit);
    window.removeEventListener('touchstart', handleAppInit);
    window.removeEventListener('scroll', handleAppInit);
  };

  return (
    <Sentry.ErrorBoundary>
      <LocaleContext.Provider value={{ locale, setLocale, localesContent }}>
        <CurrencyContext.Provider value={{ currency, setCurrency }}>
          <AppInitContext.Provider value={{ hasAppInit, setHasAppInit }}>
            <FunnelContext.Provider
              value={{
                externalFunnelLoaded,
                setExternalFunnelLoaded,
              }}
            >
              <FullStory />
              <Component {...pageProps} />
              <CookieNotification />
            </FunnelContext.Provider>
          </AppInitContext.Provider>
        </CurrencyContext.Provider>
      </LocaleContext.Provider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
