import { PRODUCTION } from 'constants/environment';
import ReactGA from 'react-ga';

export const useAnalytics = (): {
  init: (trackingId: string, options?: ReactGA.InitializeOptions) => void;
  trackPageViewed: (path?: string) => void;
  trackEvent: (params: ReactGA.EventArgs, callback?: () => void) => void;
} => {
  return {
    init(trackingId, options) {
      ReactGA.initialize(trackingId, options);
    },
    trackPageViewed(path) {
      if (!PRODUCTION) {
        return;
      }

      if (path) {
        ReactGA.pageview(path);
        return;
      }

      ReactGA.pageview(window.location.pathname + window.location.search);
    },
    trackEvent(params, callback) {
      if (PRODUCTION) {
        ReactGA.event(params);
      }

      callback?.();
    },
  };
};

/**
 * Set the value of a custom dimension of name `name` to the value `value`
 *
 * Not exported as a hook to be used within util functions as well
 */
export const setCustomDimension = (
  dimensionName: string,
  value: unknown,
): void => {
  ReactGA.set({ [dimensionName]: value });
};
