export enum STAGE {
  LOCAL = 'local',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export const LOCAL = process.env.STAGE === STAGE.LOCAL;

export const STAGING = process.env.STAGE === STAGE.STAGING;

export const PRODUCTION = process.env.STAGE === STAGE.PRODUCTION;

export const STATIC_PATH_FALLBACK = process.env.STATIC_OUTPUT === '1';
